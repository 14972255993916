import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import '../sass/main.scss'
import { Helmet } from "react-helmet"
import checkIcon from '../images/svg/003-check.svg'
import pinIcon from '../images/svg/005-pin.svg'
import busIcon from '../images/svg/004-bus.svg'
import phoneIcon from '../images/svg/002-phone-call.svg'
import GMap from "../components/GMap"
import HomeSlides from "../components/HomeSlides"

const IndexPage = () => (
  <>
    <noscript>
      <iframe title="google-tag" src="https://www.googletagmanager.com/ns.html?id=GTM-K4JXCGGK"
        height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe>
    </noscript>
    <div>
      <Helmet>
        <script>
          {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-K4JXCGGK');
      `}
        </script>
        <title>Dentista de Urgencia - Dolor de Muela - Extracción de muela</title>
        <meta name="keywords"
          content="odontología, dentista, odontólogo, dientes,urgencias odontológicas, dolor de muelas, Extracción de muelas, muelas" />
        <meta name="description"
          content="Consultorio odontológico y atención domiciliaria 24hs, odontología, dentista, odontólogo, dientes,urgencias odontológicas, dolor de muelas, Extracción de muelas, muelas" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-28727806-1"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {dataLayer.push(arguments); }
          gtag('js', new Date());

          gtag('config', 'UA-28727806-1');
        `}
        </script>
        <link rel="preconnect" href="https://maps.google.com" />
        <link rel="preconnect" href="https://www.google-analytics.com" />
      </Helmet>
      <section id="home" className="mb-5 d-flex flex-column vh-100">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="mt-2">
                <h1>
                  ODONTOLOGO <b className="text-danger text-nowrap">URGENCIAS 24HS</b>
                </h1>
                <h4 className="d-xl-flex">
                  <address className="font-weight-normal mb-0 mr-1">
                    Pareja 2657, Capital Federal
                  </address>
                  <span>
                    <b className="text-nowrap">15 4411-7775</b>
                  </span>
                </h4>
                <div className="sticky-top mt-3 mb-2">
                  <a className="btn btn-primary btn-lg btn-block d-md-none" href="tel:1544117775">LLAMAR</a>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <nav className="d-flex justify-content-around text-center w-100 align-items-center flex-wrap">
                <a className="btn" href="#servicios">Servicios</a>
                <a className="btn btn-outline-primary" href="#como-llegar">Cómo llegar</a>
              </nav>
            </div>
          </div>
        </div>
        <HomeSlides />
      </section>
      <section id="servicios" className="my-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="text-center text-primary font-weight-bold mb-5">Servicios 24hs</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 d-flex">
              <div className="pr-2">
                <img src={checkIcon} width="32" height="32" className="border rounded p-1" alt="✓" />
              </div>
              <div className="content">
                <p className="text-uppercase">Extracciones las 24hs</p>
              </div>
            </div>
            <div className="col-md-4 d-flex">
              <div className="pr-2">
                <img src={checkIcon} width="32" height="32" className="border rounded p-1" alt="✓" />
              </div>
              <div className="content">
                <p className="text-uppercase">Tratamientos de conducto las 24hs</p>
              </div>
            </div>
            <div className="col-md-4 d-flex" data-delay="500">
              <div className="pr-2">
                <img src={checkIcon} width="32" height="32" className="border rounded p-1" alt="✓" />
              </div>
              <div className="content">
                <p className="text-uppercase">Prótesis fija y removible en el día</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 d-flex">
              <div className="pr-2">
                <img src={checkIcon} width="32" height="32" className="border rounded p-1" alt="✓" />
              </div>
              <div className="content">
                <p className="text-uppercase">Cirugía de retenidos las 24hs</p>
              </div>
            </div>
            <div className="col-md-4 d-flex" data-delay="250">
              <div className="pr-2">
                <img src={checkIcon} width="32" height="32" className="border rounded p-1" alt="✓" />
              </div>
              <div className="content">
                <p className="text-uppercase">Agregado de dientes en el acto las 24hs</p>
              </div>
            </div>
            <div className="col-md-4 d-flex" data-delay="500">
              <div className="pr-2">
                <img src={checkIcon} width="32" height="32" className="border rounded p-1" alt="✓" />
              </div>
              <div className="content">
                <p className="text-uppercase">Compostura de prótesis en el acto las 24hs</p>
              </div>
            </div>
            <div className="col-md-4 d-flex" data-delay="500">
              <div className="pr-2">
                <img src={checkIcon} width="32" height="32" className="border rounded p-1" alt="✓" />
              </div>
              <div className="content">
                <p className="text-uppercase">Dientes provisorios en el acto las 24hs</p>
              </div>
            </div>
            <div className="col-md-4 d-flex" data-delay="500">
              <div className="pr-2">
                <img src={checkIcon} width="32" height="32" className="border rounded p-1" alt="✓" />
              </div>
              <div className="content">
                <p className="text-uppercase">Tratamiento del dolor, aperturas, drenajes</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="p-5 bg-dark">
        <div className="container">
          <div className="col-md-12 text-center">
            <h2 className="text-white">Llámenos ahora <b>15 4411-7775</b>
            </h2>
            <a className="btn btn-outline-primary btn-lg mt-3 d-md-none" data-animation="bounceIn" href="tel:1544117775">Llamar
              ahora</a>
          </div>
        </div>
      </div>
      <div className="row m-0 facilities">
        <div className="col-4 p-0">
          <StaticImage src="../images/facilities/cons2.jpg" className="img-fluid" alt="Instrumental" />
        </div>
        <div className="col-4 p-0">
          <StaticImage src="../images/facilities/cons4.jpg" className="img-fluid" alt="Sala de espera" />
        </div>
        <div className="col-4 p-0">
          <StaticImage src="../images/facilities/cons5.jpg" className="img-fluid" alt="Consultorio" />
        </div>
      </div>

      <section id="como-llegar" className="my-5">
        <GMap apiKey='AIzaSyBRxsQXBLs6jlv7yA1VIWgTOjJU1JsKZEo' />
      </section>
      <section>
        <div className="container my-5">
          <div className="row">
            <div className="col-md-4 d-flex">
              <div className="pr-2">
                <img src={pinIcon} width="32" alt="Pin" className="border p-2 rounded border-primary" />
              </div>
              <div className="content">
                <h4 className="text-uppercase font-weight-bold">Dirección</h4>
                <p>
                  Pareja 2657<br />
                  Villa Pueyrredón <br />
                  Ciudad Autónoma de Buenos Aires<br />
                  Ver en&nbsp;
                  <a target="_blank"
                    rel="noreferrer"
                    href="https://www.google.com/maps/place/Odontologo+Urgencia+24+horas/@-34.5881637,-58.4983617,17z/data=!3m1!4b1!4m5!3m4!1s0x95bcb647cb916d41:0xc05d363d0fe8ae82!8m2!3d-34.5881637!4d-58.496173">
                    Google Maps
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-4 d-flex">
              <div className="pr-2">
                <img src={busIcon} width="32" height="32" alt="Transporte"
                  className="border p-2 rounded border-primary" />
              </div>
              <div className="content">
                <h4 className="text-uppercase font-weight-bold">Transporte público</h4>
                <p className="pr-3">
                  <b>Colectivos: </b> 114, 107, 87, 110, 111, 108, 168, 176, 123 <br />
                  <b>Tren: </b> Línea Mitre (Estación Villa Pueyrredón), Línea Urquiza (Estación Dr. Francisco Beiró)
                </p>
              </div>
            </div>
            <div className="col-md-4 d-flex">
              <div className="pr-2">
                <img src={phoneIcon} width="32" height="32" alt="Telefonos"
                  className="border p-2 rounded border-primary" />
              </div>
              <div className="content">
                <h4 className="text-uppercase font-weight-bold">Teléfonos 24hs</h4>
                <p>
                  Celular: 15 4411-7775
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="footer my-5">
        <div className="container">
          <div className="text-center">
            Copyright ©2021 Odontólgo de Urgencias 24hs - Ciudad de Buenos Aires - Argentina
          </div>
          <div className="text-center text-muted mt-5">
            mecanico dental, protesis, urgencia, dentista, odontologo, 24 hs, 24 horas, odontologia, guardia, mecanica
            dental, odontologica, protesis, fija, emergencia, dolor, muela, dia, acto, 1, una, extraccion, tratamiento,
            conducto, cirugia, extraer, endodoncia, centro, dentadura, postiza, reparacion, juicio, cirujano, exodoncia,
            arreglo, diente, corona, provisoria, compostura, niños, chicos, odontopediatria, bucal, removible, completa,
            dentales, urgencias, dentistas, odontologos, guardias, odontologicas, emergencias, muelas, extracciones,
            tratamientos, conductos, cirugias, centros, dentaduras, postizas, cirujanos, dientes, coronas, provisorias,
            composturas, removibles, completas
          </div>
        </div>
      </div>
    </div>
  </>
)

export default IndexPage
