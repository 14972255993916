import React, { useEffect, useRef, useState } from "react";

export default function GMap ({ apiKey }) {
  const mapRef = useRef(null)
  const [from, setFrom] = useState('')
  const myLatLng = useRef(null)
  const directionsDisplay = useRef(null)
  const [directionsError, setDirectionsError] = useState(false)

  useEffect(() => {
    const onLoad = () => {
      const google = window.google
      console.log('Initialize map')
      myLatLng.current = new google.maps.LatLng(-34.5882622, -58.4961076);
      directionsDisplay.current = new google.maps.DirectionsRenderer();

      var mapOptions = {
        zoom: 14,
        center: myLatLng.current,
        disableDefaultUI: true,
        scrollwheel: true,
        navigationControl: false,
        mapTypeControl: false,
        scaleControl: false,
        draggable: true,
        mapTypeControlOptions: {
          mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'roadatlas']
        }
      };

      var map = new google.maps.Map(mapRef.current, mapOptions);

      directionsDisplay.current.setMap(map);
      //directionsDisplay.setPanel(document.getElementById("directions"));

      var marker = new google.maps.Marker({
        position: myLatLng.current,
        map: map,
        title: '',
      });

      var contentString = '<div style="max-width: 300px" id="content">' +
        '<div id="bodyContent">' +
        '<h5 class="color-primary"><strong>Odontólogo 24 horas</strong></h5>' +
        '<p style="font-size: 12px">Urgencias odontológicas 24hs todos los días del año.</p>' +
        '</div>' +
        '</div>';

      var infowindow = new google.maps.InfoWindow({
        content: contentString
      });

      google.maps.event.addListener(marker, 'click', function () {
        infowindow.open(map, marker);
      });

      var styledMapOptions = {
        name: 'US Road Atlas'
      };

      var roadAtlasStyles = {};

      var usRoadMapType = new google.maps.StyledMapType(
        roadAtlasStyles, styledMapOptions);

      map.mapTypes.set('roadatlas', usRoadMapType);
      map.setMapTypeId('roadatlas');
    }

    if (!window.google) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `https://maps.google.com/maps/api/js?key=${apiKey}`
      script.addEventListener('load', onLoad)
      const x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(script, x)
    } else {
      onLoad()
    }
  }, [apiKey])

  const calcRoute = async () => {
    setDirectionsError(false)
    const google = window.google
    const directionsService = new google.maps.DirectionsService();
    var request = {
      origin: from,
      destination: myLatLng.current,
      travelMode: google.maps.DirectionsTravelMode.DRIVING
    };
    try {
      await directionsService.route(request, function (response, status) {
        if (status === google.maps.DirectionsStatus.OK) {
          directionsDisplay.current.setDirections(response);
          console.log(response)
        }
      })
    } catch (err) {
      setDirectionsError(true)
      console.log('error', err)
    }
  }

  return (
    <div>
      <div className="container d-flex flex-column align-items-center">
        <h1 className="text-center text-primary font-weight-bold">Cómo llegar al consultorio</h1>
        <p>Ingrese la dirección de su ubicación actual en el cuadro indicando calle, número y
        localidad y el mapa le mostrará el recorrido para llegar al consultorio.</p>
        <input value={from} type="text" className="form-control" onChange={e => setFrom(e.target.value)} />
        <button className="btn btn-primary mt-3" onClick={calcRoute} >Mostrar recorrido</button>
        {directionsError && (
          <div className="alert alert-danger mt-2">
            No pudimos encontrar la dirección ingresada
          </div>
        )}
      </div>
      <div className="map-wrapper my-3">
        <div ref={mapRef} style={{ width: '100%', height: '300px' }}>
        </div>
      </div>
    </div>
  )

}
