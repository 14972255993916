import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  overflow: hidden;
`

const ImageArea = styled.div`
  grid-area: 1/1;
  position: relative;
  place-items: center;
  display: grid;
`

const TextArea = styled.div`
  background-color: rgba(0,0,0,0.5);
  text-align: center;
  display: grid;
  @media(min-width: 768px) {
    margin: 20%;
    padding: 20px;
  }
`

const Title = styled.h3`
  color: #fff;
  font-size: 45px;
  font-weight: bold;
`

const Subtitle = styled.h4`
  color: #fff;
`

export default function HomeSlides () {
  return (
    <Wrapper className="flex-grow-1 mt-2">
      <div className="h-100" style={{ display: "grid" }}>
        <StaticImage
          quality={100}
          style={{
            gridArea: '1/1'
          }}
          layout='fullWidth'
          src="../images/facilities/cons1.jpg"
          className="d-block w-100 h-100"
          alt="..." />
        <ImageArea>
          <TextArea>
            <Title>Urgencias odontológicas 24hs todos los días del año</Title>
            <Subtitle>Atención inmediata niños y adultos</Subtitle>
          </TextArea>
        </ImageArea>
      </div>
    </Wrapper>
  )
}
